import React, { useMemo, useState } from "react"
import { Formik, Form, Field } from "formik"
import Bounce from "react-reveal/Bounce"
import { withSnackbar } from "react-simple-snackbar"
import { trackPromise } from "react-promise-tracker"
import * as yup from "yup"

import messageSentLogo from "../../assets/message-sent.png"
import snackBarSettings from "../../components/snackBarSettings"

import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import { TextField } from "formik-material-ui"

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

const axios = require(`axios`)

const ContactForm = ({ openSnackbar, closeSnackbar }) => {
  const intl = useIntl()
  const [messageSent, setMessageSent] = useState(false)

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup.string().required(
          intl.formatMessage({
            id: "contactForm_requiredFieldName",
            defaultMessage: "Wprowadź imię",
          })
        ),
        email: yup
          .string()
          .required(
            intl.formatMessage({
              id: "contactForm_requiredFieldEmail",
              defaultMessage: "Wprowadź email",
            })
          )
          .email(
            intl.formatMessage({
              id: "contactForm_formatFieldEmail",
              defaultMessage: "Wprowadź poprawny email",
            })
          ),
        message: yup.string().required(
          intl.formatMessage({
            id: "contactForm_formatFieldMessage",
            defaultMessage: "Wprowadź wiadomość",
          })
        ),
      }),
    [intl]
  )

  let contactContent = null
  if (!messageSent) {
    contactContent = (
      <>
        <h3>
          <FormattedMessage
            id="contactForm_title"
            defaultMessage="Wyślij wiadomość"
          />
        </h3>
        <Formik
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            trackPromise(
              axios
                .post(process.env.GATSBY_CONTACT, values, {
                  auth: {
                    user: "carebits-mail-sender",
                    passsword: "MlzSfan8jYBlfPIKMGd566sN4Ia5WTYk",
                  },
                })
                .then(() => setMessageSent(true))
                .catch(error =>
                  openSnackbar(
                    intl.formatMessage({
                      id: "contactForm_errorMessage",
                      defaultMessage: "Nie udało się wysłać wiadomości...",
                    }),
                    4000
                  )
                )
                .finally(() => actions.setSubmitting(false))
            )
          }}
          initialValues={{
            email: "",
            name: "",
            message: "",
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box py={3}>
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label={intl.formatMessage({
                    id: "contactForm_lblFieldEmail",
                    defaultMessage: "Email",
                  })}
                  color="secondary"
                  fullWidth
                />
              </Box>
              <Box py={3}>
                <Field
                  component={TextField}
                  name="name"
                  type="text"
                  label={intl.formatMessage({
                    id: "contactForm_lblFieldName",
                    defaultMessage: "Imię",
                  })}
                  color="secondary"
                  fullWidth
                />
              </Box>
              <Box pt={3} pb={10}>
                <Field
                  component={TextField}
                  name="message"
                  type="text"
                  label={intl.formatMessage({
                    id: "contactForm_lblFieldMessage",
                    defaultMessage: "Wiadomość",
                  })}
                  color="secondary"
                  multiline={true}
                  fullWidth
                />
              </Box>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                className="contact-form-submit"
                fullWidth
              >
                <FormattedMessage
                  id="contactForm_btnSubmit"
                  defaultMessage="Wyślij wiadomość"
                />
              </Button>
            </Form>
          )}
        </Formik>
      </>
    )
  } else {
    contactContent = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Bounce up>
          <h3>
            <FormattedMessage
              id="contactForm_successMessageTitle"
              defaultMessage="Dziękujemy za kontakt!"
            />
          </h3>
          <p>
            <FormattedMessage
              id="contactForm_successMessage"
              defaultMessage="Na Twoje pytania odpowiemy najszybciej jak to możliwe."
            />
          </p>
          <img src={messageSentLogo}></img>
        </Bounce>
      </Box>
    )
  }
  return (
    <>
      <Paper className="contact-card-box">{contactContent}</Paper>
    </>
  )
}

export default withSnackbar(ContactForm, snackBarSettings)

import React from "react"
import PropTypes from "prop-types"
import CircularNavButton from "../components/circularNavButton"
import NavButton from "../components/navButton"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"

const BannerNav = ({
  boxText,
  boxLink,
  circularText,
  circularLink,
  colorClass,
}) => {
  return (
    <Box className="nav-banner" mt={5}>
      <Grid container wrap="wrap" alignItems="center" spacing={3}>
        <Grid item>
          <NavButton
            text={boxText}
            link={boxLink}
            className={colorClass}
          ></NavButton>
        </Grid>
        <Grid item>
          <CircularNavButton
            text={circularText}
            link={circularLink}
            className={colorClass}
          ></CircularNavButton>
        </Grid>
      </Grid>
    </Box>
  )
}

CircularNavButton.propTypes = {
  boxText: PropTypes.string,
  boxLink: PropTypes.string,
  colorClass: PropTypes.string,
  circularText: PropTypes.string,
  circularLink: PropTypes.string,
}

CircularNavButton.defaultProps = {
  boxText: ``,
  boxLink: `/`,
  colorClass: `primary`,
  circularText: ``,
  circularLink: `/`,
}

export default BannerNav

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactBanner from "../sections/contact/contact-banner"
import MainMarketingBanner from "../sections/main-marketing-banner"
import SnackbarProvider from "react-simple-snackbar"
import LoadingIndicator from "../components/LoadingIndicator"

import { useIntl } from "gatsby-plugin-react-intl"

const ContactPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <LoadingIndicator></LoadingIndicator>
      <SEO
        lang={intl.locale}
        title={data.strapiContact.seo.title}
        description={data.strapiContact.seo.description}
        keywords={data.strapiContact.seo.keywords}
      />
      <SnackbarProvider>
        <ContactBanner cms={data.strapiContact}></ContactBanner>
        <MainMarketingBanner
          cms={data.strapiMain.marketingBanner}
        ></MainMarketingBanner>
      </SnackbarProvider>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiContact {
      seo {
        title
        description
        keywords {
          tag
        }
      }
      title
      company_name
      company_address
      call_center
      cooperation
      google_map_frame
    }
    strapiMain {
      marketingBanner {
        title
        content
        link_bar {
          box_title
          box_link
          circular_title
          circular_link
        }
      }
    }
  }
`

export default ContactPage

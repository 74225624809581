import React from "react"
import Markdown from "react-markdown"
import ContactForm from "./contactForm"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"

const ContactBanner = ({ cms }) => {
  return (
    <>
      <div className="contact-banner">
        <Container maxWidth="xl" disableGutters={true}>
          <Box display="flex" flexDirection="column">
            <Container>
              <Box
                className="contact-container"
                display="flex"
                flexDirection="column"
              >
                <section className="contact-info-section">
                  <Box mb={5}>
                    <h2>{cms.title}</h2>
                  </Box>
                  <b>
                    <Markdown allowDangerousHtml className="accented">
                      {cms.company_name}
                    </Markdown>
                  </b>
                  <Markdown allowDangerousHtml>{cms.company_address}</Markdown>
                  <Markdown allowDangerousHtml className="boldy">
                    {cms.call_center}
                  </Markdown>
                  <Markdown allowDangerousHtml className="boldy">
                    {cms.cooperation}
                  </Markdown>
                </section>
                <ContactForm></ContactForm>
              </Box>
            </Container>
            <div className="map-container">
              <iframe
                src={cms.google_map_frame}
                title="Company on Google Maps"
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
              ></iframe>
            </div>
          </Box>
        </Container>
      </div>
    </>
  )
}

export default ContactBanner
